<template>
  <div>
    <div class="p-grid">
      <div class="p-col">
        <h3><i class="pi pi-file" style="font-size: 1.6rem"></i> {{ (typeof $route.params.emitidos != 'string' || $route.params.emitidos == '')?'Análises':'Apólices Emitidas' }} de Seguro Fiança</h3>
      </div>
      <div class="p-col p-text-right">
        <Button v-if="$auth.currentUser.imobiliaria == null" label="exportar" icon="pi pi-file-excel" class="p-button p-button-primary p-mr-4"  @click="exportar" />
        <Button v-if="$auth.userCan('SEGURO_FIANCA_CADASTRAR')" label="nova análise" icon="pi pi-plus" class="p-button p-button-secondary"  @click="$router.push({ path: `/processo` })" />
      </div>
    </div>

    <div class="p-grid">
      <div class="p-col">
        <AutoComplete v-model="filtrosSelecionados" :multiple="true" :suggestions="filtrosResultados" @complete="filtrarAutoComplete($event)" 
        field="completeLabel" optionGroupLabel="label" :completeOnFocus="true" optionGroupChildren="items" placeholder="Filtre seus resultados" style="display: block">
          <template #item="slotProps">
            <div>{{slotProps.item.label}}</div>
          </template>
        </AutoComplete>
      </div>
    </div>

    <DataTable sortMode="single" :sortField="(typeof $route.params.emitidos != 'string' || $route.params.emitidos == '')?'data':'data_atualizacao'" :sortOrder="-1" :value="processos" :loading="loading" selectionMode="single"  v-model:selection="processo" 
    :lazy="true" :paginator="true" :first="firstPageProcessos" :rows="perPageProcessos" @page="onPageOrSort($event)" @sort="onPageOrSort($event)" :totalRecords="totalProcessos">
      <Column header="">
        <template #body="slotProps">
          <i class="pi pi-exclamation-triangle" style="color: red;" v-if="slotProps.data.withTicket" v-tooltip.top="'Ticket pendente para esta ficha'" />
        </template>
      </Column>

      <Column field="id" header="Negócio" class="col-reduzida" :sortable="true"></Column>
      <Column field="locatario_item.nome" header="Locatário"></Column>
      <Column field="data" header="Data Cadastro" :sortable="true" v-if="typeof $route.params.emitidos != 'string' || $route.params.emitidos == ''">
        <template #body="slotProps">
          {{ $utils.formatDateTimeToBr(slotProps.data.data) }}
        </template>
      </Column>
      <Column field="data_atualizacao" header="Data Atualização" :sortable="true" v-else>
        <template #body="slotProps">
          {{ $utils.formatDateTimeToBr(slotProps.data.data_atualizacao) }}
        </template>
      </Column>
      <Column header="Resultados">
        <template #body="slotProps">
          <div v-if="slotProps.data.resultados.filter(obj => obj.analisado == false || obj.aprovado == true).length == 0">
            -
          </div>
          <div v-else class="resultados">
            <div v-for="r of slotProps.data.resultados" :key="r.id" :class="('logo-resultado ' + (r.analisado?'analisado':'') + ' ' + (r.aprovado?'aprovado':''))">
              <img :src="'/assets/layout/images/' + r.logo.replace('logo-','ico-').replace('.png','.jpeg')" :alt="r.nome" />
            </div>
          </div>
        </template>
      </Column>
      <Column field="data_expiracao" header="Expira Em" :sortable="true">
        <template #body="slotProps">
          <span v-if="$utils.daysFromNow(slotProps.data.data_expiracao) > 0">{{ $utils.daysFromNow(slotProps.data.data_expiracao) }} dia(s) </span>
          <span v-else>-</span>
        </template>
      </Column>

      <Column field="status" header="Status" :sortable="true">
        <template #body="slotProps">
          <Tag 
            :value="status[slotProps.data.status].label"
            :severity="status[slotProps.data.status].severity"
            :class="(typeof status[slotProps.data.status].class != 'undefined')?status[slotProps.data.status].class:''"
          >
          </Tag>
        </template>
      </Column>
      <Column class="col-actions">
        <template #body="slotProps">
          <div class="text-right">
            <Button icon="pi pi-trash" v-tooltip.top="'Arquivar Análise'" v-if="$auth.userCan('SEGURO_FIANCA_EXCLUIR') && slotProps.data.status != 'CONCLUIDO' && slotProps.data.status != 'NEGADO'" class="p-button-rounded p-button-danger p-mr-2" @click="excluir($event, slotProps.data)" />
            <Button icon="pi pi-clone" v-tooltip.left="'Clonar Análise'" v-if="$auth.userCan('SEGURO_FIANCA_CADASTRAR')" class="p-button-rounded p-button-text" @click="$router.push({ path: `/processo/clonar/${slotProps.data.id}` })" />
          </div>
        </template>
      </Column>
      <template #empty>
          Nenhuma Análise encontrada.
      </template>
    </DataTable>

  </div>

  <ConfirmPopup></ConfirmPopup>
  <Toast position="top-right" />

</template>

<script>

export default {
  
  data() {
    return {

      // AUTOCOMPLETE OPCIONAL: //
      filtrosPersonalizados: 'Negócio/Locatário',
      // AUTOCOMPLETE OBRIGATORIOS: //
      filtrosSelecionados: null,
      filtrosResultados: [],
      filtrosOpcoes: [],
      // FIM AUTOCOMPLETE //

      loading: true,
      filtrosQuery: "",
      pageQuery: "",
      sortQuery: "sortBy=data&sort=-1",
      api_get: '?fields=id,novo,data,data_atualizacao,data_expiracao,status&relationships=imovel_item:id_proprio;locatario_item:nome',

      processos: [],
      totalProcessos: 0,
      perPageProcessos: 20,
      firstPageProcessos: 0,
      processo: null,

      status: {
        
        'NOVO' : {
          label: 'ANÁLISE',
          severity: "info",
          icon: "pi pi-clock",
          class: 'tag-novo',
        },
        'PROCESSANDO' : {
          label: 'PROCESSANDO',
          severity: "warning",
          icon: "pi pi-clock",
        },
        'APROVADO' : {
          label: 'APROVADO',
          severity: "success",
          icon: "pi pi-exclamation-triangle",
        },
        'RECUSADO' : {
          label: 'RECUSADO',
          severity: "danger",
          icon: "pi pi-times",
        },
        'EM_CONTRATACAO' : {
          label: 'CONTRATAÇÃO',
          severity: "warning",
          icon: "pi pi-clock",
        },
        'AGUARDANDO_CONTRATO': {
          label: 'AGUARDANDO CONTRATO',
          severity: "warning",
          icon: "pi pi-file",
          class: 'tag-contrato',
        },
        'SEGURO_REGISTRADO': {
          label: 'EMITIDO',
          severity: "info",
          icon: "pi pi-check",
        },
        'REGISTRO_NEGADO' : {
          label: 'NEGADO',
          severity: "danger",
          icon: "pi pi-times",
        },
        'APOLICE_CANCELADA' : {
          label: 'CANCELADO',
          severity: "danger",
          icon: "pi pi-times",
        },
        'EXPIRADO' : {
          label: 'EXPIRADO',
          severity: "danger",
          icon: "pi pi-exclamation-triangle",
          class: "tag-expirado"
        },
        'EXCLUIDO' : {
          label: 'EXCLUÍDO',
          severity: "danger",
          icon: "pi pi-trash",
        },

        'AGUARDANDO_EMISSAO' : {
          label: 'AGUARDANDO EMISSÃO',
          severity: "warning",
          icon: "pi pi-clock",
          class: 'tag-emitindo'
        },

      },

    };
  },

  mounted() {

    const self = this;

    this.$api.get('/processos/filtros').then(function (response) {

      const dados = response.data;

      if(dados.success) {

        self.filtrosOpcoes = dados.data;
 
      }
      
    });

    this.carregarInformacoes();

  },

  watch: {

    $route() {

      if(this.$route.name == "processos") {

        if(typeof this.$route.params.emitidos == "string" && this.$route.params.emitidos != "") {

          this.filtrosQuery = '{"status":["SEGURO_REGISTRADO"]}';
          this.filtrosSelecionados = [{
            completeLabel : "Status: APÓLICE EMITIDA",
            group : "status",
            id : "SEGURO_REGISTRADO",
            label : "APÓLICE EMITIDA",
          }];

        } else {

          this.filtrosQuery = "";
          this.filtrosSelecionados = null;

        }

      }

    },

    processo(val) {

      if(val) {
        this.$router.push({ path: `/processo/${val.id}` });
      }

    },

    filtrosSelecionados(v) {

      this.filtrosQuery = "";

      if(Array.isArray(v) && v.length > 0) {
        let p = {};
        for(const f of v) {
          if(typeof p[f.group] == "undefined")
            p[f.group] = [];
          p[f.group].push(f.id);
        }
        this.filtrosQuery = JSON.stringify(p); //TODO PASSAR PARA A URL PARA QUE QUANDO A PESSOA ATUALIZAR A TELA OS FILTROS PERMANEÇAM
      }

      this.carregarInformacoes();

    },

  },

  methods: {

    filtrarAutoComplete: function($e) {

      const self = this;

      const q = $e.query.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();

      var ids_selecionados = [];
      if(self.filtrosSelecionados != null) {
        self.filtrosSelecionados.forEach((i) => {
          ids_selecionados.push(i.id);
        });
      }

      self.filtrosResultados = [];
      for (const _g of Object.values(self.filtrosOpcoes)) {
        const g = Object.assign({}, _g);
        var el = [];
        for (const _e of Object.values(g.items)) {
          const e = Object.assign({}, _e);
          if(e.label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").indexOf(q) >= 0) {
            if(ids_selecionados.indexOf(e.id) == -1) {
              e.completeLabel = g.label + ': ' + e.label; // Mudança na apresentação do dado selecionado
              el.push(e);
            }
          }
        }
        if(el.length > 0) {
          g.items = el;
          self.filtrosResultados.push(g);
        }
      }

      if(typeof self.filtrosPersonalizados == 'string') {

        self.filtrosResultados.push({
          label: self.filtrosPersonalizados, id: 'str', 
          items: [
            {label: $e.query, id: $e.query, group: 'str', completeLabel: self.filtrosPersonalizados + ': ' + $e.query}
          ]
        });

      }

    },

    carregarInformacoes: async function() {

      this.loading = true;

      try {

        let url = '/processos'  + this.api_get;

        if(this.filtrosQuery != "")
          url += '&filters=' + this.filtrosQuery;

        if(this.pageQuery != "")
          url += '&page=' + this.pageQuery;

        if(this.sortQuery != "")
          url += '&' + this.sortQuery;

        const response = await this.$api.get(url);
            
        const dados = response.data;

        if(dados.success) {

          const data = dados.data;

          this.processos = data.data;
          this.firstPageProcessos = data.to - data.per_page;
          this.perPageProcessos = data.per_page;
          this.totalProcessos = data.total;

        }

        this.loading = false;
      
      } catch (e) {

        console.error(e);
        this.loading = false;

      }

    },

    exportar: async function() {

      this.loading = true;

      try {

        let url = '/processos'  + this.api_get;

        if(this.filtrosQuery != "")
          url += '&filters=' + this.filtrosQuery;

        if(this.pageQuery != "")
          url += '&page=' + this.pageQuery;

        if(this.sortQuery != "")
          url += '&' + this.sortQuery;

        const response = await this.$api.get(url + "&export=xlsx");
            
        window.open(response.data.data, "_blank");

        this.loading = false;

      } catch (e) {

        console.error(e);
        this.loading = false;

      }

    },

    excluir(event, obj) {

      const self = this;

      this.$confirm.require({
        target: event.currentTarget,
        message: 'Tem certeza que deseja excluir?',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-danger',
        accept: () => {

          this.$api.post('/processos/' + obj.id, {
            '_method' : 'DELETE'
          }).then(function (response) {

            if(response.data.success) {

              for(const k of Object.keys(self.processos)) {
                if(self.processos[k].id == obj.id) {
                  self.processos.splice(k,1);
                  break;
                }
              }

              self.$toast.add({severity:'success', summary: 'Sucesso!', detail: response.data.message, life: 3000});

            } else {
              self.$toast.add({severity:'error', summary: 'ERRO!', detail: response.data.message, life: 5000});
            }

          }).catch(function (erro) {

            if(typeof erro.response != "undefined")
              self.$toast.add({severity:'error', summary: 'ERRO!', detail: erro.response.data.message, life: 5000});

          });

        }
      });
      
    },

    onPageOrSort(event) {

      this.sortQuery = "";
      if(typeof event.sortField == "string" && event.sortField != "") {
        this.sortQuery += "sortBy=" + event.sortField;
        if(typeof event.sortOrder == "number") {
          this.sortQuery += "&sort=" + event.sortOrder.toString();
        }
      }

      this.pageQuery = (event.first/event.rows+1).toString();

      this.carregarInformacoes();

    },

  }
};

</script>

<style scoped>

  :deep(.col-reduzida) {
    width: 120px;
  }

  :deep(.p-tag.tag-novo) {
    background-color: #ff3399;
  }

  :deep(.p-tag.tag-contrato) {
    background-color: #ff8133;
    color: white;
  }

  :deep(.p-tag.tag-expirado) {
    background-color: #ccc;
    color: black;
  }

  :deep(.p-tag.tag-emitindo) {
    background-color: #977fc6;
    color: white;
  }

  .logo-resultado img {
    height: 34px
  }

  :deep(.resultados) {
    width: 155px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px
  }

  .logo-resultado {
    position: relative;
  }

  .logo-resultado:not(.aprovado) img {
    filter: grayscale(100%);
    opacity: 0.25;
  }

  .logo-resultado.analisado:not(.aprovado) {
    display: none !important;
  }

  .logo-resultado.analisado:not(.aprovado) img {
    opacity: 0.85;
  }

  .logo-resultado.analisado:not(.aprovado)::after {
    content: "X";
    display: block;
    color: red;
    text-shadow: 0 0 4px white;
    font-weight: bold;
    bottom: 4px;
    width: 100%;
    text-align: center;
    font-size: 20px;
    position: absolute;
  }

</style>